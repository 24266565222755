import React, { Component } from 'react'
import check from '../img/check.png'
import error from '../img/error.png'
import '../styles/ItemSma.css'

class ItemSma extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    set_image() {
        if (this.props.mensaje === 'Datos validados y almacenados correctamente') {
            return check
        }
        else {
            return error;
        }
    }

    render() {
        return (
            <li class="Item-sma">
                <div class="div-image">
                    <img class="img-status"
                        alt="charge problem"
                        src={this.set_image()} />
                </div>
                <div class="div-mensaje">
                    <label class="label-mensaje">Mensaje: {this.props.mensaje}</label>
                    <label class="label-fecha">Id: {this.props.IdVerificación}</label>
                    <label class="label-fecha">Fecha: {this.props.fecha}</label>
                </div>
            </li>
        );
    }
}

export default ItemSma;