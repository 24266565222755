import React from 'react';
import './App.css';
import { BrowserRouter as Router,
  Route
  } from "react-router-dom";
import home from './views/Home'


function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar/>  */}
        <Route exact path ="/:id/:procces_id" component={home}/>
      </div>
    </Router>

  );
}

export default App;
