import { SHOW_REGISTROS } from '../actions'

const initialState = {
    list: []
}

export function showRegistros(state = initialState, action) {
    
    switch (action.type) {
        case SHOW_REGISTROS:
            return Object.assign({}, state, {list: action.payload})
        default:
            return state 
    }
    
}