import '../styles/Home.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { showRegistros } from '../actions'
import ItemSma from '../components/ItemSma'



export class Home extends Component {

    constructor(props) {
        super();
        this.state = {
            registros: [],
            charge: true
        }
        this.redender_registers.bind(this);
    }

    componentWillMount() {
        this.props.showRegistros(this.props.match.params.id, this.props.match.params.procces_id)
    }

    redender_registers() {
        return this.props.registros
    }

    render() {
        let a = this.redender_registers()
        return (
            <div className='Body'>
                <ul>
                    {Object.keys(a).map((keyName, i) => (
                        <ItemSma
                            mensaje={a[keyName].respuesta_sma['mensaje']
                                ?
                                a[keyName].respuesta_sma['mensaje']
                                :
                                a[keyName].respuesta_sma['Mensaje']
                            }
                            IdVerificación={
                                a[keyName].respuesta_sma['mensaje'] ?
                                    a[keyName].respuesta_sma['IdVerificación'] :
                                    ''
                            }
                            fecha={a[keyName].fecha}
                            id={a[keyName].id}
                        />
                    ))}
                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    registros: state.registros.list,
})

const mapDispatchToProps = {
    showRegistros: showRegistros
}



export default connect(mapStateToProps, mapDispatchToProps)(Home)