import axios from 'axios'

export const SHOW_REGISTROS = 'SHOW_REGISTROS'

export function showRegistros(id_esatcion, id_proceso) {
    
    return (dispatch, getState) => {
        axios.get('https://sma.weboard.cl/recolector/registros_sma/?datos_envio__id_estacion='+id_esatcion+'&datos_envio__id_proceso_id='+id_proceso+'&ordering=-id')
            .then((response) => {
                dispatch( { type: SHOW_REGISTROS, payload: response.data.results } ) 
            }) 
    }
    
} 
